import React, { Component } from 'react';
import { Navbar, Nav, Dropdown, BSpan } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import './Navigator.css';
import './About/About';
import './Gallery/Gallery';
import './Gallery/Art';
import './Gallery/Decals';
import './Gallery/Vinyl-Swatches';
import './Gallery/Jewelry';
import './Gallery/Textiles';
import './Gallery/Thoughtful-Thursday';
import './Contact/Contact';
import './Home/Home';

export default class Navigator extends Component {
  render() {
    return (
      <Navbar expand="lg" fixed="top" className="header">
        <Navbar.Brand className="logo"><img src="/img/Vixen_Vision_Logo.svg" alt="Vixen Vision Design" height="80px" id="top-logo"></img></Navbar.Brand>
        <Navbar.Toggler target="#navbar" />
        <Navbar.Collapse id="navbar">
          <Navbar.Nav className="justify-content-end header" justify-content="flex-end">
            <Nav.ItemLink href="/" active>
              Home
              <BSpan srOnly>(current)</BSpan>
            </Nav.ItemLink>
            <Nav.ItemLink href="/about" as={Link} to="/about">About</Nav.ItemLink>
            <Nav.ItemLink href="/contact" as={Link} to="/contact">Contact</Nav.ItemLink>
            <Nav.Item dropdown>
              <Nav.Link dropdownToggle href="/gallery" as={Link} to="gallery" id="galleryDropdown">
                Gallery
              </Nav.Link>
              <Dropdown.Menu aria-labelledby="galleryDropdown">
                <Dropdown.Item href="/gallery" as={Link} to="/gallery">Gallery List</Dropdown.Item>
                <Dropdown.Item href="/gallery/art" as={Link} to="/gallery/art">Art</Dropdown.Item>
                <Dropdown.Item href="/gallery/decals" as={Link} to="/gallery/decals">Decals</Dropdown.Item>
                <Dropdown.Item href="/gallery/vinyl-swatches" as={Link} to="/gallery/vinyl-swatches">Vinyl Swatches</Dropdown.Item>
                <Dropdown.Item href="/gallery/jewelry" as={Link} to="/gallery/jewelry">Jewelry</Dropdown.Item>
                <Dropdown.Item href="/gallery/textiles" as={Link} to="/gallery/textiles">Textiles</Dropdown.Item>
                <Dropdown.Item href="/gallery/thoughtful-thursday" as={Link} to="/gallery/thoughtful-thursday">Thoughtful Thursday</Dropdown.Item>
              </Dropdown.Menu>
            </Nav.Item>            
            <Nav.Item dropdown>
              <Nav.Link
                dropdownToggle
                href="#"
                id="shopDropdown">
                Shop
              </Nav.Link>
              <Dropdown.Menu aria-labelledby="shopDropdown">
                <Dropdown.Item href="https://vixenvisiondesign.shop">Art, Decor, &amp; Jewelry</Dropdown.Item>
                <Dropdown.Item href="https://www.spoonflower.com/profiles/vixenvision">Textiles on Spoonflower</Dropdown.Item>
              </Dropdown.Menu>
            </Nav.Item>
            {/* <Nav.ItemLink href="https://ReclaimedMatter.com" as={Link} to="https://ReclaimedMatter.com">Blog</Nav.ItemLink> */}
          </Navbar.Nav>
        </Navbar.Collapse>
        <a href="https://vixenvisiondesign.shop" className="button small d-none d-md-block" target="_blank" rel="noopener noreferrer">Shop Now!</a>
      </Navbar>
    )
  }
}
