import React, { Component } from 'react';
import {  } from 'react-router-dom';
import './Gallery.css';

export default class ThoughtfulThurs extends Component {
render () {
    return (
        <div className="main-section">
          <div>
            <h1>Gallery</h1>
            <h2>Thoughtful Thursday</h2>
            <h3>Design Challenge</h3>
            <h4>After attending Adobe MAX in October 2020, I was inspired to stretch my design muscles on the regular. Every week I post a #ThoughtfulThursday quote image and post about a variety of subjects. Here are a list of those images. Click through to read the Instagram or Blog post!</h4>
            <div id="ttGallery" className="grid-container space-above space-below center">
                <div id="tt2021Posts" className="full-width-grid">
                    <h5>2021</h5>
                    <div className="ttItem"><a href="https://reclaimedmatter.com/support-your-squad" title="Support Your Squad"><img src="../img/tt_gallery/2021/03-25-2021-Support-Your-Squad.webp" alt="Support Your Squad" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CMkgZx1s4jS" title="Own Your Average"><img src="../img/tt_gallery/2021/03-18-2021-Own-your-average.webp" alt="Own Your Average" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CMSoRlHMPWL" title="Be the Light In the Dark Room"><img src="../img/tt_gallery/2021/03-11-2021-Be-the-Light-In-the-Dark-Room.webp" alt="Be the Light In the Dark Room" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CMAoNvWsbRJ" title="Don't Let Imposter Syndrome Drag You Down"><img src="../img/tt_gallery/2021/03-04-2021-Imposter-Syndrome.webp" alt="Don't Let Imposter Syndrome Drag You Down" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CLufD-Rsy-N" title="You Can't Pour from an Empty Cup"><img src="../img/tt_gallery/2021/02-25-2021-Empty-cup.webp" alt="You Can't Pour from an Empty Cup" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CLclr6rst9G" title="Imperfections are Beautiful"><img src="../img/tt_gallery/2021/02-17-2021-Imperfections-are-Beautiful.webp" alt="Imperfections are Beautiful" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CLKihqkMinb" title="Disrupt to Create"><img src="../img/tt_gallery/2021/02-11-2021-Disrupt-to-Create.webp" alt="Disrupt to Create" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CK4kTDRsPuy" title="Mistakes Are How We Learn"><img src="../img/tt_gallery/2021/02-04-2021-Mistakes-are-how-we-learn.webp" alt="Mistakes Are How We Learn" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CKnMN5dM5Ye" title="Always Be Creating"><img src="../img/tt_gallery/2021/01-28-2021-Always-Be-Creating.webp" alt="Always Be Creating" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CKUdMJ8spUJ" title="Change is a Constant"><img src="../img/tt_gallery/2021/01-21-2021-Change-is-a-Constant.webp" alt="Change is a Constant" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CKC50eDsuU8" title="Code Feeds My Soul"><img src="../img/tt_gallery/2021/01-14-2021-Code-feeds-my-soul.webp" alt="Code Feeds My Soul" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CJwXSmZsiBi" title="Soft Skills are as Important as Hard Skills"><img src="../img/tt_gallery/2021/01-07-2021-Soft-skills-are-as-important.webp" alt="Soft Skills are as Important as Hard Skills" /></a></div>
                </div>
                <div id="tt2020Posts" className="full-width-grid">
                    <h5>2020</h5>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CJefPSFMjzc" title="Endings are also Beginnings"><img src="../img/tt_gallery/2020/12-31-2020-Endings-are-also-beginnings.webp" alt="Endings are also Beginnings" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CJMSdZEspWI" title="May Your Days Be Geeky and Bright"><img src="../img/tt_gallery/2020/12-24-2020-May-Your-Days-Be.webp" alt="May Your Days Be Geeky and Bright" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CIoUIeaMZto" title="Celebrate Your Milestones"><img src="../img/tt_gallery/2020/12-10-2020-Celebrate-Milestones.webp" alt="Celebrate Your Milestones" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CIWnatpnfID" title="Exercise Compassion"><img src="../img/tt_gallery/2020/12-03-2020-Exercise-Compassion.webp" alt="Exercise Compassion" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CIEMy4rHcfo" title="Be Thankful"><img src="../img/tt_gallery/2020/11-26-2020-Be-Thankful.webp" alt="Be Thankful" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CHyNa1UHqzx" title="Invest in your Growth"><img src="../img/tt_gallery/2020/11-19-2020-Invest-in-Your-Growth.webp" alt="Invest in your Growth" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CHf-mB2nqkC" title="Perfection is an Illusion"><img src="../img/tt_gallery/2020/11-12-2020-Perfection-is-an-Illusion.webp" alt="Perfection is an Illusion" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CHOKbzenBnQ" title="Crisis Illustrates Importance"><img src="../img/tt_gallery/2020/11-05-2020-Crisis-Illustrates-Importance.webp" alt="Crisis Illustrates Importance" /></a></div>
                    <div className="ttItem"><a href="https://www.instagram.com/p/CG8AyU9HXeT" title="Be Kind To Yourself"><img src="../img/tt_gallery/2020/10-29-2020-Be-Kind-To-Yourself.webp" alt="Be Kind To Yourself" /></a></div>
                </div>
                <div id="instagramFollowCard" className="full-width-grid">
                <a href="https://www.instagram.com/vixenvisiondesign/" title="Follow Me on Instagram"><img src="../img/follow-me-on-instagram.webp" alt="Follow Me on Instagram" /></a></div>
            </div>    
            </div>
        </div>
    )
}
}
