import React, { Component } from 'react';
import { Container, Card } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import './Gallery.css';

export default class Gallery extends Component {
  render() {
    return (
      <Container as="gallery" role="document">
        <div className="main-section">
          <h1>Gallery</h1>
          <Card>
            <Card.Body>
              <h2>Art</h2>
              <Card.Text>Our painting, print, and card art allow you to express your inner geek. Perfect for your home or office.<br/><br/></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Link href="/gallery/art" as={Link} to="/gallery/art">View Art</Card.Link>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <h2 className="decal-card">Decals</h2>
              <Card.Text>Breathe new life into everyday items with our unique decal designs.<br/><br/></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Link href="/gallery/decals" as={Link} to="/gallery/decals">View Decals</Card.Link>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <h2 className="jewelry-card">Jewelry</h2>
              <Card.Text>Current and past creations to satisfy your inner geek. Unique jewelry with earthy and repurposed elements.<br/></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Link href="/gallery/jewelry" as={Link} to="/gallery/jewelry">View Jewelry</Card.Link>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <h2>Textiles</h2>
              <Card.Text>View our fabric patterns that we sell on Spoonflower.<br/><br/></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Link href="/gallery/textiles" as={Link} to="/gallery/textiles">View Textiles</Card.Link>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <h2>Thoughtful Thursday</h2>
              <Card.Text>A record of the #ThoughtfulThursday posts from social media.<br/><br/></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Link href="/gallery/thoughtful-thursday" as={Link} to="/gallery/thoughtful-thursday">View Thoughtful Thursday</Card.Link>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <h2>Vinyl Swatches</h2>
              <Card.Text>Take a look at all of the colors and types of vinyl we offer for your projects!<br/><br/></Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Link href="/gallery/vinyl-swatches" as={Link} to="/gallery/vinyl-swatches">View Vinyl Swatches</Card.Link>
            </Card.Footer>
          </Card>
        </div>
      </Container>
    )
  }
}

