import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import './Gallery.css';

export default class Art extends Component {
render() { 
return (
        <Container as="art" role="document">
        <div className="main-section">
                <h1>Gallery</h1>
                <div id="firstGallery" className="clearfix">
                        <h2>Art - Paper Paintings</h2>
                        <h3>Original Gel Plate Prints & Gouache</h3>
                        <h4>Acrylic multimedia, Gouache, and watercolor paper paintings.<br/>Vibrant creations of abstract, nature, and pop culture.</h4>
                        {/* <a href="https://vixenvisiondesign.shop/collections/paintings" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop" rel="noopener noreferrer">Buy Prints</a>
                        <p>Click the images labeled "Buy Now" to purchase. Message me for Gel Plate Prints that aren't listed.</p> */}
                        <p>The originals and limited edition prints will be in the shop soon! Message me to purchase the in the meantime.</p>
                        <div id="imageGallery" className="space-above space-below center">                           
                                <div id="gelPlateGallery" className="grid-container space-above space-below center">
                                        <div id="paperpaintings" className="full-width-grid">
                                        <div className="decalItem"><img src="../img/paintings_gallery/Jurassic-Park-300x300.webp" alt="Jurassic Park - Gouache - 2024 - 8x10" loading="lazy" /><p className="artCaption">Jurassic Park - Gouache - 2024 - 8x10 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Fighter-At-Rest-300x300.webp" alt="Fighter At Rest - Gouache - 2024 - 8x10" loading="lazy" /><p className="artCaption">Fighter At Rest - Gouache - 2024 - 8x10 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Manzanita-300x300.webp" alt="Manzanita - Gouache - 2024 - 8x10" loading="lazy" /><p className="artCaption">Manzanita - Gouache - 2024 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Attack-of-the-Kaiju-300x300.webp" alt="Attack of the Kaiju - Gouache - 2023 - 8x10" loading="lazy" /><p className="artCaption">Attack of the Kaiju - Gouache - 2023 - 8x10 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Azure-and-Emerald-Whispers.webp" alt="Azure and Emerald Whispers 2023 - 8x10" loading="lazy" /><p className="artCaption">Azure and Emerald Whispers 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/father-and-son.webp" alt="Father and Son 2023 - 8x10" loading="lazy" /><p className="artCaption">Father and Son 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Gleaming-Undercurrents.webp" alt="Gleaming Undercurrents 2023 - 8x10" loading="lazy" /><p className="artCaption">Gleaming Undercurrents 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Glowing-Feathered-Fury.webp" alt="Glowing Feathered Fury 2023 - 8x10" loading="lazy" /><p className="artCaption">Glowing Feathered Fury 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Golden-Neon-Melody.webp" alt="Golden Neon Melody 2023 - 8x10" loading="lazy" /><p className="artCaption">Golden Neon Melody 2023 - 8x10</p></div> 
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Illusory-Prowler.webp" alt="Illusory Prowler 2023 - 8x10" loading="lazy" /><p className="artCaption">Illusory Prowler 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/luminous-leafy-fusion.webp" alt="Luminous Leafy Fusion 2023 - 8x10" loading="lazy" /><p className="artCaption">Luminous Leafy Fusion 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Mystic-Chromatic-Symphony.webp" alt="Mystic Chromatic Symphony 2023 - 8x10" loading="lazy" /><p className="artCaption">Mystic Chromatic Symphony 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Neon-Fox-Mirage.webp" alt="Neon Fox Mirage 2023 - 8x10" loading="lazy" /><p className="artCaption">Neon Fox Mirage 2023 - 8x10 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Raging-Neon-Dragonfire.webp" alt="Raging Neon Dragonfire 2023 - 8x10" loading="lazy" /><p className="artCaption">Raging Neon Dragonfire 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Rebel-Resonance.webp" alt="Rebel Resonance 2023 - 8x10" loading="lazy" /><p className="artCaption">Rebel Resonance 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Strange-New-Worlds.webp" alt="Strange New Worlds 2023 - 8x10" loading="lazy" /><p className="artCaption">Strange New Worlds 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/String-Theory.webp" alt="String Theory 2023 - 8x10" loading="lazy" /><p className="artCaption">String Theory 2023 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Verdant-Enigma.webp" alt="Verdant Enigma 2023 - 8x10" loading="lazy" /><p className="artCaption">Verdant Enigma 2003 - 8x10</p></div> 
                                        <div className="decalItem"><img src="../img/gelprints_gallery/Whispering-Lagoon.webp" alt="Whispering Lagoon 2023 - 8x10" loading="lazy" /><p className="artCaption">Whispering Lagoon 2003 - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/gelprints_gallery/zesty-citrus-canopy.webp" alt="Zesty Citrus Canopy 2023 - 8x10" loading="lazy" /><p className="artCaption">Zesty Citrus Canopy 2023 - 8x10</p></div>
                                        </div>
                                </div>
                        </div> 
                </div>

                <div><hr></hr></div>
                <div id="secondGallery" className="clearfix">
                        <h2>Art - Canvas Paintings</h2>
                        <h3>Galaxy and Nebula Paintings</h3>
                        <h4>Acrylic canvas paintings with a geeky flair. Display your love of space in your space.</h4>
                        <a href="https://vixenvisiondesign.shop/collections/paintings" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop" rel="noopener noreferrer">Buy Paintings</a>
                        <p>Click the images labeled "Buy Now" to purchase. Message me for paintings that aren't listed.</p>
                        <div id="imageGallery" className="space-above space-below center">                           
                                <div id="paintingGallery" className="grid-container space-above space-below center">
                                        <div id="paintings" className="full-width-grid">
                                        <div className="decalItem"><img src="../img/paintings_gallery/Youthful-Joy-300x300.webp" alt="Youthful Joy 2024 - 8x8" loading="lazy" /><p className="artCaption">Youthful Joy 2024 - 8x8</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/I-Choose-to-Let-Go-300x300.webp" alt="I Choose to Let Go - 2024 - 8x8" loading="lazy" /><p className="artCaption">I Choose to Let Go 2024 - 8x8</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/The-Purple-Lotus-300x300.webp" alt="The Purple Lotus - 2024 - 9x12" loading="lazy" /><p className="artCaption">The Purple Lotus 2024 - 9x12</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Kaiju-Sayonara-300x300.webp" alt="Kaiju Sayonara 2023 - 12x12" loading="lazy" /><p className="artCaption">Kaiju Sayonara 2023 - 12x12 - SOLD</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/neon-revolution-11x14-abstract-painting" title="Neon Revolution 2023 - 11x14" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/Neon-Revolution-300x300.webp" alt="Neon Revolution 11x14 Abstract Painting" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Neon Revolution 2023 - 11x14</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/neon-commotion-8x10-abstract-painting" title="Neon Commotion 2023 - 8x10" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/Neon-Commotion-300x300.webp" alt="Neon Commotion 2023 8x10 Abstract Painting" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Neon Commotion 2023 - 8x10</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/blazers-pleiadian-dream-9x12-geek-galaxy-painting" title="Blazers' Pleiadian Dream 2023 - 9x12" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/Blazers-Pleidian-Dream-300x300.webp" alt="Blazers' Pleiadian Dream 2023 - 9x12 Geek Galaxy Painting" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Blazers' Pleiadian Dream 2023 - 9x12</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/vgk-bright-knight-11x14-abstract-painting" title="VGK Bright Knight 2022 - 11x14" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/VGK-Bright-Knight-300x300.webp" alt="VGK Bright Knight 2022 - 11x14 Abstract Nebula Painting" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">VGK Bright Knight 2022 - 11x14</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/rosette-nebula-ngc-2244-6x6-mini-painting" title="Rosette Nebula NGC 2244 2022 - 6x6" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/Rosette-Nebula-300x300.webp" alt="Rosette Nebula NGC 2244 2022 - 6x6" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Rosette Nebula NGC 2244 2022 - 6x6</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Invasion-in-the-heart-of-the-colonies-300x300.webp" alt="Invasion of the Heart of the Colonies 2022 - 16x20" loading="lazy" /><p className="artCaption">Invasion of the Heart of the Colonies 2022 - 16x20 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Thunder-Cats-Paw-Nebula-300x300.webp" alt="Thunder Cats Paw Nebula 2022 - 6x6" loading="lazy" /><p className="artCaption">Thunder Cats Paw Nebula 2022 - 6x6</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Triforce-Butterfly-Nebula-300x300.webp" alt="Triforce Butterfly Nebula 2022 - 6x6" loading="lazy" /><p className="artCaption">Triforce Butterfly Nebula 2022 - 6x6 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Mini-Gold-Jedi-M51-Whirlpool-Galaxy-300x300.webp" alt="Mini Gold Jedi M51 Whirlpool Galaxy 2022 - 4x4" loading="lazy" /><p className="artCaption">Mini Gold Jedi M51 Whirlpool Galaxy 2022 - 4x4</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/weyland-yutani-painting-300x300.webp" alt="Weyland-Yutani Reticuli 2022 - 18x24" loading="lazy" /><p className="artCaption">Weyland-Yutani Reticuli 2022 - 18x24</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Eye-of-Jupiter-300x300.webp" alt="So Say We All - Eye of Jupiter - 11x14" loading="lazy" /><p className="artCaption">So Say We All - Eye of Jupiter - 11x14 - SOLD</p></div> 
                                        <div className="decalItem"><img src="../img/paintings_gallery/Enterprise-A-Starburst Galaxy-M82-300x300.webp" alt="Enterprise A Starburst Galaxy M82 2021- 16x20" loading="lazy" /><p className="artCaption">Enterprise A Starburst Galaxy M82 2021- 16x20</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/so-say-we-all-butterfly-300x300.webp" alt="So Say We All - Butterfly Nebula - 16x20" loading="lazy" /><p className="artCaption">So Say We All - Butterfly Nebula - 16x20 - SOLD</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/d20-orion-nebula-10x10-geek-galaxy-painting-ready-to-hang" title="D20 Orion Nebula 10x10 Geek Galaxy Painting" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/D20-Orion-Nebula-300x300.webp" alt="D20 Orion Nebula 10x10" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">D20 Orion Nebula 10x10</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/california-nebula-in-perseus-ngc-1499-4x4-mini-painting-ready-to-hang" title="California Nebula in Perseus - NGC 1499 - 4x4 Mini Painting" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/Mini-California-Nebula-in-Perseus-300x300.webp" alt="Mini California Nebula in Perseus - 4x4" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Mini California Nebula in Perseus - 4x4</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/So-Say-We-All-Eye-of-Jupiter-2022-300x300.webp" alt="So Say We All Eye of Jupiter 2022 9x12" loading="lazy" /><p className="artCaption">So Say We All Eye of Jupiter 2022 9x12 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Mini-NGC-2170-The-Angel-Nebula-300x300.webp" alt="Mini Angel Nebula NGC-2170 - 4x4" loading="lazy" /><p className="artCaption">Mini Angel Nebula NGC-2170 - 4x4</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/dont-panic-hitchhikers-guide-8x8-geek-galaxy-painting-ready-to-hang" title="Don't Panic Hitchhiker's Guide 8x8 Geek Galaxy Painting" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/Dont-Panic-Red-300x300.webp" alt="Don't Panic Hitchhiker's Guide 8x8" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Don't Panic Hitchhiker's Guide 8x8</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/D20-Abstract-Nebula-300x300.webp" alt="D20 Abstract Nebula 8x10" loading="lazy" /><p className="artCaption">D20 Abstract Nebula 8x10 - SOLD</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/peachy-princess-leia-purple-and-teal-nebula-5x7-geek-galaxy-painting-ready-to-hang" title="Peachy Princess Leia Purple and Teal Nebula 5x7 Geek Galaxy Painting" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/leia-300x300.webp" alt="Peachy Princess Leia Purple and Teal Nebula 5x7 Geek Galaxy Painting" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Princess Leia Purple and Teal Nebula 5x7 Painting</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Mini-Heart-Nebula-4x4-300x300.webp" alt="Mini Heart Nebula - 4x4" loading="lazy" /><p className="artCaption">Mini Heart Nebula - 4x4 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Mini-30-Doradus-Nebula-4x4-300x300.webp" alt="Mini 30 Doradus Nebula - 4x4" loading="lazy" /><p className="artCaption">Mini 30 Doradus Nebula - 4x4</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/pawn-mini-nebulas-300x300.webp" alt="Three Mini Pawn Nebula Paintings - 4x4" loading="lazy" /><p className="artCaption">Three Mini Pawn Nebula Paintings - 4x4</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Christmas-Tree-Cluster-Cone-Nebula-300x300.webp" alt="Christmas Tree and Cone Nebulas - 6x6" loading="lazy" /><p className="artCaption">Christmas Tree and Cone Nebulas - 6x6</p></div>
                                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/neon-pawn-nebula-5x7-geek-galaxy-painting-ready-to-hang" title="Neon Pawn Nebula 5x7 Geek Galaxy Painting" target="_blank" rel="noopener noreferrer"><img src="../img/paintings_gallery/neon-pawn-300x300.webp" alt="Neon Pawn Nebula 5x7 Geek Galaxy Painting" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">D20 Abstract Nebula 8x10 Geek Galaxy Painting</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/nintendo-controller-300x300.webp" alt="Nintendo Nebula - 8x10" loading="lazy" /><p className="artCaption">Nintendo Nebula - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/Ace-and-Deuce-Galaxy-300x300.webp" alt="Ace and Deuce Galaxy - 8x10" loading="lazy" /><p className="artCaption">Ace and Deuce Galaxy - 8x10 - SOLD</p></div> 
                                        <div className="decalItem"><img src="../img/paintings_gallery/Andromeda-Atari-300x300.webp" alt="Andromeda Atari - 8x8" loading="lazy" /><p className="artCaption">Andromeda Atari - 8x8 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/This-is-the-way-300x300.webp" alt="This is the Way - 8x10" loading="lazy" /><p className="artCaption">This is the Way - 8x10</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/vsfa-enterprise-painting-2021-300x300.webp" alt="Enterprise A 2021- 16x20" loading="lazy" /><p className="artCaption">Enterprise A 2021 - 16x20 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/ryder-jedi-300x300.webp" alt="Jedi Ryder - 8x10" loading="lazy" /><p className="artCaption">Jedi Ryder - 8x10 - SOLD</p></div>
                                        <div className="decalItem"><img src="../img/paintings_gallery/unicorn-audrey-300x300.webp" alt="Unicorn Audrey - 8x10" loading="lazy" /><p className="artCaption">Unicorn Audrey - 8x10 - SOLD</p></div>
                                        </div>
                                </div>
                        </div> 
                </div>

                <div><hr></hr></div>
                <div id="thirdGallery" className="clearfix">   
                        <h2>Art - Prints and Posters</h2>
                        <h3>Make your space your own!</h3>
                        <h4>Proudly display your geekiness at home, work, or school.</h4>
                        <a href="https://vixenvisiondesign.shop/collections/art-cards-posters-and-prints" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop" rel="noopener noreferrer">Buy Art Prints</a>
                        <p>CMessage me for prints that aren't listed.</p>
                        <div id="imageGallery3" className="space-above space-below center">                           
                                <div id="paintingGallery" className="grid-container space-above space-below center">
                                        <div id="prints" className="full-width-grid">
                                                <div className="decalItem"><img src="../img/print_gallery/Atari-300x300.webp" alt="Atari 2600 11x14 Art Poster" loading="lazy" /><p className="artCaption">Atari 2600 11x14 Art Poster</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/big-fan-of-space-outer-and-personal-11x14-art-poster" title="Big Fan of Space - Outer and Personal 11x14 Art Poster" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/Big-Fan-of-Space-300x300.webp" alt="Big Fan of Space - Outer and Personal 11x14 Art Poster" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Big Fan of Space - Outer and Personal 11x14 Art Poster</p></div>
                                                <div className="decalItem"><img src="../img/print_gallery/I-Object-300x300.webp" alt="I Object Spock Star Trek Quote 11x17 Art Poster" loading="lazy" /><p className="artCaption">I Object Spock Star Trek Quote 11x17 Art Poster</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/d20-orion-nebula-8x8-art-print" title="D20 Orion Nebula - 8x8 Art Print" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/D20-Orion-Nebula-300x300.webp" alt="D20 Orion Nebula - 8x8 Art Print" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">D20 Orion Nebula - 8x8 Art Print</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/thunder-cats-paw-nebula-8x8-art-print" title="Thunder Cats Paw Nebula - 8x8 Art Print" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/Thunder-Cats-Paw-Nebula-300x300.webp" alt="Thunder Cats Paw Nebula - 8x8 Art Print" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Thunder Cats Paw Nebula - 8x8 Art Print</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/i-fight-for-the-users-11x17-art-poster" title="I Fight for the Users 11x17 Art Poster" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/IFightForTheUsers-300x300.webp" alt="I Fight for the Users 11x17 Art Poster" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">I Fight for the Users 11x17 Art Poster</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/negative-i-am-a-meat-popsicle-quote-art-poster" title="Negative I Am A Meat Popsicle 11x17 Art Poster" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/MeatPopsicle-300x300.webp" alt="Negative I Am A Meat Popsicle 11x17 Art Poster" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Negative I Am A Meat Popsicle 11x17 Art Poster</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/tears-in-rain-quote-11x17-art-poster" title="Tears in Rain 11x17 Art Poster" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/TearsInRain-300x300.webp" alt="Tears in Rain 11x17 Art Poster" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Tears in Rain 11x17 Art Poster</p></div>
                                                <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/three-things-cannot-be-long-hidden-11x17-art-poster" title="Three Things Cannot Be Long Hidden 11x17 Art Poster" target="_blank" rel="noopener noreferrer"><img src="../img/print_gallery/ThreeThings-300x300.webp" alt="Three Things Cannot Be Long Hidden 11x17 Art Poster" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Three Things Cannot Be Long Hidden 11x17 Art Poster</p></div>
                                                <div className="decalItem"><img src="../img/print_gallery/inthishouse-v2-300x300.webp" alt="In This House Poster Version 2 - 11x17" loading="lazy" /><p className="artCaption">In This House Poster Version 2 - 11x17</p></div>
                                                <div className="decalItem"><img src="../img/print_gallery/inthishouse-300x300.webp" alt="In This House Poster Version 1 - 11x17" loading="lazy" /><p className="artCaption">In This House Poster Version 1 - 11x17</p></div>
                                                <div className="decalItem"><img src="../img/print_gallery/inthishouse-large-posters-300x300.webp" alt="In This House Poster - 20x30" loading="lazy" /><p className="artCaption">In This House Poster Version 1 - 20x30</p></div>

                                        </div>
                                </div>
                        </div>  
                </div>
                
                <div><hr></hr></div>
                <div id="fourthGallery" className="clearfix">   
                        <h2>Art - Sculpture</h2>
                        <p>Message me for sculptures that aren't listed as SOLD.</p>
                        <div id="imageGallery4" className="space-above space-below center">                           
                                <div id="sculptureGallery" className="grid-container space-above space-below center">
                                        <div id="sculpture" className="full-width-grid">
                                                <div className="decalItem"><img src="../img/sculpture_gallery/Elements-of-the-Sea-300x300.webp" alt="Elements of the Sea Assemblage Sculpture 2024 - 12x12" loading="lazy" /><p className="artCaption">Elements of the Sea - Assemblage - 2024 - 12x12 - SOLD</p></div>
                                                <div className="decalItem"><img src="../img/sculpture_gallery/Be-Wild-300x300.webp" alt="Be Wild Sculpture Assemblage 2024 - 12x18" loading="lazy" /><p className="artCaption">Be Wild - Assemblage - 2024 - 12x18</p></div>
                                                <div className="decalItem"><img src="../img/sculpture_gallery/Stardust-and-Magic-300x300.webp" alt="Stardust and Magic Assemblage Sculpture 2024 - 7x7" loading="lazy" /><p className="artCaption">Stardust and Magic - Assemblage - 2024 - 7x7</p></div>
                                                <div className="decalItem"><img src="../img/sculpture_gallery/I-Am-Abundant-300x300.webp" alt="I Am Abundant Assemblage Sculpture 2023 - 6x12" loading="lazy" /><p className="artCaption">I Am Abundant - Assemblage - 2023 - 6x12 - SOLD</p></div>
                                                <div className="decalItem"><img src="../img/sculpture_gallery/I-Am-Authentic-300x300.webp" alt="I Am Authentic Assemblage Sculpture 2023 - 10x10" loading="lazy" /><p className="artCaption">I Am Authentic - Assemblage - 2023 - 10x10 - SOLD</p></div>
                                                <div className="decalItem"><img src="../img/sculpture_gallery/Art-is-Life-300x300.webp" alt="Art is Life Plaster Sculpture 2023 - 14x22" loading="lazy" /><p className="artCaption">Art is Life - Plaster - 2023 - 14x22</p></div>
                                                <div className="decalItem"><img src="../img/sculpture_gallery/Nostalgia-300x300.webp" alt="Nostalgia Wood Sculpture 2023 - 14x18" loading="lazy" /><p className="artCaption">Nostalgia - Wood - 2023 - 14x18</p></div>
                                        </div>
                                </div>
                        </div> 
                </div>  
                <h2>Open for commissions!</h2>
                <div>Fill out our <a href="/contact" as={Link} to="/contact" title="Contact Us">commission request form</a> if you're interested in a commission piece.</div>  
        </div>
        </Container>
        )
}
}