import React, { Component } from 'react';
import { Alert, Form, Button, BDiv } from 'bootstrap-4-react';
import emailjs from 'emailjs-com';
import './Contact.css';

export default class CommissionForm extends Component {

    constructor (props) {
      super(props);
      this.state = {
        firstName: '',
        lastName: '',
        email: '',
        commissionType: '',
        notes: '',
        showAlert: 'none',
        errorAlert: 'none'
      }
    }
  
    handleUserInput = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({[name]: value});
      //console.log({[name]: value});
    }

    handleSubmit = (e) => {

      console.log(this.state);
      if (!this.canBeSubmitted()) {
        this.setState({
          errorAlert: 'block'
        })       
      }
      e.preventDefault();
      emailjs.sendForm('service_2cijsib', 'template_dx0qh82', e.target, 'user_TLnZ0updwyhVijtZzCeKF')
      .then((result) => {
        console.log('SUCCESS!', result.status, result.text);
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          commissionType: '',
          notes: '',
          showAlert: 'block',
          errorAlert: 'none'
        })

      }, (error) => {
          console.log('FAILED...', error.text);
          this.setState({
            errorAlert: 'block'
          })
      });
  }

  canBeSubmitted() {
    const { email, firstName } = this.state;
    return email.length > 5 && firstName.length >= 2;
  }

    render () {
      const isEnabled = this.canBeSubmitted();

      return (
        <Form role="form" className="space-below" onSubmit={this.handleSubmit}>
            <h2>Commission Request</h2>
            <BDiv id="successAlert" style={{display: this.state.showAlert}} role="alert">
              <Alert success><strong>Thank you for your request!</strong><br></br>We'll respond to you in 3-7 business days. We're excited to see what we can create together!</Alert>
            </BDiv>
            <BDiv id="errorAlert" style={{display: this.state.errorAlert}} role="alert">
              <Alert className="alert alert-danger"><strong>Uh oh! Something went wrong.</strong><br></br>Please make sure you use a properly formatted email address (like email@site.com) and fill out all the fields.</Alert>
            </BDiv>
            <Form.Group>
            <label htmlFor="firstName">First Name</label>
            <Form.Input type="text" id="firstName" name="firstName" placeholder="First Name" className="form-control" defaultValue={this.state.firstName} onChange={this.handleUserInput}/>
            </Form.Group>
            <Form.Group>
            <label htmlFor="lastName">Last Name</label>
            <Form.Input type="text" id="lastName" name="lastName" placeholder="Last Name" className="form-control" defaultValue={this.state.lastName} onChange={this.handleUserInput}/>
            </Form.Group>
            <Form.Group>
            <label htmlFor="email">Email address</label>
            <Form.Input type="email" id="email" name = "email" required="required" placeholder="Enter email" className="form-control" defaultValue={this.state.email} onChange={this.handleUserInput} />
            <Form.Text text="muted">We'll never share your email with anyone else. Please use a properly formatted email address such as email@site.com.</Form.Text>
            </Form.Group>
            <Form.Group>
            <label htmlFor="type">Commission Type</label>
            <Form.CustomSelect type="select" id="commissionType" name="commissionType" mb="3" className="form-control" onChange={this.handleUserInput}>
            <option defaultValue={this.state.commissionType}>Select a commission type</option>
            <option value="decal">Decal</option>
            <option value="fabric-design">Fabric/Textile Design</option>
            <option value="jewelry">Jewelry</option>
            <option value="art-painting">Painting</option>
            <option value="art-print">Prints</option>
            <option value="other">Other</option>
            </Form.CustomSelect>
            </Form.Group>
            <Form.Group>
            <label htmlFor="notes">Describe your idea</label>
            <Form.Textarea type="textarea" id="notes" name="notes" rows="4" defaultValue={this.state.notes} onChange={this.handleUserInput}></Form.Textarea>
            </Form.Group>
            <Form.Group>
              <BDiv className="g-recaptcha" data-sitekey="6LfpqB0UAAAAAGg-L_YCU_reyqHlzkHi0ujYzabT"> </BDiv>
            </Form.Group>
            <Button role="button" id="commissionFormBtn" primary type="submit" className="btn btn-primary" disabled={!isEnabled}>Send Request</Button>
        </Form>
      )
    }
  }
