import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';

export default class Decals extends Component {

render () {
    return (
        <div className="main-section">
          <div>
            <h1>Gallery</h1>
            <h2>Decals</h2>
            <h4>We design decals for cars, bikes, glassware, tumblers, signs, notebooks, clothing, housewares, laptop cases, and whatever else you can imagine. </h4>
            <div>
              <p>Check out the <a href="/gallery/vinyl-swatches" as={Link} to="/gallery/vinyl-swatches" title="Vinyl Swatches">Vinyl Swatches</a> to see colors and vinyl options.</p>
              <a href="https://vixenvisiondesign.shop/collections/decals" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop" rel="noopener noreferrer">Buy Decals</a>
            </div>          
            <div id="imageGallery" className="space-above space-below center">
              <div id="decalGallery" className="grid-container space-above space-below center">
                  <div id="decalsCustom" className="full-width-grid">
                      <h5>Geek Decals</h5>
                      <p>My geek decals bring back all those nostalic feels!</p><p>Customize your life and express your geekiness in the size and colors you choose. Click the image to purchase!</p>
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/d20-role-playing-die-geek-vinyl-decal" title="D20 Role-Playing Die Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/20-Sided-Die.webp" alt="D20 Role-Playing Die Geek Vinyl Decal" /></a></div>   
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/blade-runner-badge-geek-vinyl-decal" title="Blade Runner Badge Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Blade-Runner-Badge.webp" alt="Blade Runner Badge Geek Vinyl Decal" /></a></div>   
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/dont-panic-hitchhikers-guide-to-the-galaxy-geek-vinyl-decal" title="Don't Panic Hitchhikers Guide to the Galaxy Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/dontpanic-hitchhikers-guide.webp" alt="Don't Panic Hitchhikers Guide to the Galaxy Geek Vinyl Decal" /></a></div>   
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/earth-7th-symbol-geek-vinyl-decal" title="Earth 7th Symbol Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/earth-7th-symbol.webp" alt="Earth 7th Symbol Geek Vinyl Decal" /></a></div>
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/frak-calligraphy-geek-vinyl-decal" title="Frak Calligraphy Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/frak.webp" alt="Frak Calligraphy Geek Vinyl Decal" /></a></div>    
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/the-galactic-empire-leadership-order-power-geek-vinyl-decal" title="The Galactic Empire - Leadership - Order - Power Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Empire-Propaganda.webp" alt="The Galactic Empire - Leadership - Order - Power Geek Vinyl Decal" /></a></div>  
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/four-elements-geek-vinyl-decal" title="Four Elements Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/FOUR-ELEMENTS.webp" alt="Four Elements Geek Vinyl Decal" /></a></div> 
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/this-is-the-way-mandalorian-iron-on-heat-transfer-vinyl-decal-only-choose-your-color-and-size" title="This is the Way Mandalorian Iron-On Heat Transfer Vinyl - Decal Only" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Mandalorian-this-is-the-way.webp" alt="This is the Way Mandalorian Iron-On Heat Transfer Vinyl - Decal Only" /></a></div>         
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/meeple-board-game-geek-vinyl-decal" title="Meeple Board Game Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/meeple-cutout.webp" alt="Meeple Board Game Geek Vinyl Decal" /></a></div>  
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/buckaroo-banzai-geek-vinyl-decal" title="Buckaroo Banzai Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Buckaroo-Banzai.webp" alt="Buckaroo Banzai Geek Vinyl Decal" /></a></div> 
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/jedi-i-am-one-with-the-force-and-the-force-is-with-me-geek-vinyl-decal" title="Jedi I Am One With the Force and the Force is With Me Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/I-AM-ONE-WITH-THE-FORCE-JEDI.webp" alt="Jedi I Am One With the Force and the Force is With Me Geek Vinyl Decal" /></a></div>   
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/original-gamer-geek-vinyl-decal-perfect-for-water-bottles-and-laptops" title="Original Gamer Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Original-Gamer.webp" alt="Original Gamer Geek Vinyl Decal" /></a></div>    
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/pitter-patter-lets-get-at-er-letterkenny-iron-on-heat-transfer-vinyl-decal-only-choose-your-color-and-size" title="Pitter Patter Let's Get At 'Er Letterkenny Iron-On Heat Transfer Vinyl - Decal Only" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/wayne-letterkenny-pitter-patter-shirt.webp" alt="Pitter Patter Let's Get At 'Er Letterkenny Iron-On Heat Transfer Vinyl - Decal Only" /></a></div>                                 
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/so-say-we-all-geek-vinyl-decal" title="So Say We All Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/so-say-we-all.webp" alt="So Say We All Geek Vinyl Decal" /></a></div>  
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/blade-runner-spinner-geek-vinyl-decal" title="Blade Runner Spinner Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Spinner-Logo.webp" alt="Blade Runner Spinner Geek Vinyl Decal" /></a></div>   
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/the-answer-to-life-the-universe-and-everything-hitchhikers-guide-to-the-galaxy-geek-vinyl-decal" title="The Answer to Life, the Universe, and Everything Hitchhikers Guide to the Galaxy Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/theanswer-hgttg.webp" alt="The Answer to Life, the Universe, and Everything Hitchhikers Guide to the Galaxy Geek Vinyl Decal" /></a></div>  
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/triple-og-rebel-geek-vinyl-decal" title="Triple OG Rebel Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/triple-og-rebel.webp" alt="Triple OG Rebel Geek Vinyl Decal" /></a></div>
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/tyrell-corp-geek-vinyl-decal" title="Tyrell Corp Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/tyrell-logo.webp" alt="Tyrell Corp Geek Vinyl Decal" /></a></div>   
                      <div className="decalItem"><a href="https://vixenvisiondesign.shop/products/weyland-yutani-corp-building-better-worlds-geek-vinyl-decal" title="Weyland-Yutani Corp - Building Better Worlds Geek Vinyl Decal" target="_blank" rel="noopener noreferrer"><img src="../img/decal_gallery/Weyland-Yutani-Corp.webp" alt="Weyland-Yutani Corp - Building Better Worlds Geek Vinyl Decal" /></a></div>  
               
                  </div>
                  <div id="decalsCustom" className="full-width-grid">
                      <h5>Custom Decals</h5>
                      <div>
                        <p>I can design a custom decal just for you for a variety of uses!</p><p>Many of my customers use custom decals on creative projects such as wall decor, woodworking, t-shirts, keepsakes, and personalized gifts.</p>
                        <a href="https://vixenvisiondesign.shop/search?q=Personalized+Custom+Decal" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop - Custom Decals" rel="noopener noreferrer">Order a Custom Decal</a>
                      </div>
                      <div className="decalItem"><img src="../img/decal_gallery/adventure-awaits.webp" alt="Adventure Awaits - Drinkware" /><br/></div>
                      <div className="decalItem"><img src="../img/decal_gallery/Apparently-Im-Dramatic.webp" alt="Apparently I'm Dramatic - HTV" /><br/></div>
                      <div className="decalItem"><img src="../img/decal_gallery/Autobots-lets-roll-out.webp" alt="Autobots Roll Out - Drinkware" /><br/></div>
                      <div className="decalItem"><img src="../img/decal_gallery/dome-rose-book.webp" alt="Rose in Dome on Book" /><br/></div>
                      <div className="decalItem"><img src="../img/decal_gallery/Fairy-Unicorn-Warrior-Princess-decal.webp" alt="I'm a Fairy Unicorn Warrior Princess - HTV" /><br/></div>
                      <div className="decalItem"><img src="../img/decal_gallery/Jiji.webp" alt="Jiji from Kiki's Delivery Service" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/MR-HANDSOME.webp" alt="Mr. Handsome - HTV" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/NO-NAP-CLUB.webp" alt="No Nap Club-HTV" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/no-sleep-till-brooklyn.webp" alt="No Sleep Till Brooklyn-HTV" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/Pawn.webp" alt="Game Pawn" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/PLAYDATE-MATERIAL.webp" alt="Playdate Material-HTV" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/not-today-mom.webp" alt="Not Today Mom-HTV" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/CARDS.webp" alt="Ace and Deuce Playing Cards" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/bike.webp" alt="Mountain Bike" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/GalaxyGirl.webp" alt="Galaxy Girl-HTV" /></div> 
                      <div className="decalItem"><img src="../img/decal_gallery/VGK-Helmet.webp" alt="VGK Helmet" /></div>                    
                      <div className="decalItem"><img src="../img/decal_gallery/i-turn-a-house-into-a-home.webp" alt="I turn a house into a home" /></div>
                      <div className="decalItem"><img src="../img/decal_gallery/low-rider-corgi.webp" alt="Low Rider Corgi" /></div>
                      <div className="decalItem"><img src="../img/decal_gallery/owl-you-need-is-love.webp" alt="Owl you need is love" /></div>                      
                      <div className="decalItem"><img src="../img/decal_gallery/starburst.webp" alt="Pyrex Starburst" /></div>                     
                  </div>
                </div>
                <h2>Open for commissions!</h2>
                <div>Fill out our <a href="/contact" as={Link} to="/contact" title="Contact Us">commission request form</a> if you're interested in a commission piece.</div> 
            </div> 
          </div> 
        </div>
        )
    }
}