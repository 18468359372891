import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import './Home.css';

export default class Home extends Component {
    render() {
        return (
            <Container as="home" role="document" expand="lg" className="home" fluid>
            <header className="home-grid-container" role="banner">
                <div className="hero-content">
                    <h1>Hi! I'm Alexandria Wagner.</h1>
                    <p className="hero-text">I'm a multidisciplinary artist who creates decals, digital art posters, paintings, jewelry, accessories, decor, and more, celebrating nature, astronomy, gaming, literature, sci-fi, and fantasy. My passion is to help others express their inner geek.</p>
                    <a href="/about" className="btn btn-hero" role="button">Learn More</a> <a href="https://vixenvisiondesign.shop" className="btn btn-hero" role="button" target="_blank" rel="noopener noreferrer">Shop Now</a>
                </div>
                <div className="hero-image"><img src="/img/web_AW_024-edited.webp" alt="Alexandria Wagner" loading="lazy"/></div>
            </header> 
            <div id="email" className="grid-container">
            <div className="full-width-grid"><p>Join our mailing list to stay informed about new products, events, and exclusive deals.</p><a href="https://mailchi.mp/8484db66e5fc/newsletter-signup" className="btn btn-email" role="button">Subscribe Now</a></div>
            </div>
            <div id="value-props">
                <h3 className="valueProps">Galleries</h3>
                <Link to="/gallery/art"><button className="valueProps" data-hover="Make Your Space Your Own" title="Art"><span>Art</span></button></Link>
                <Link to="/gallery/decals"><button className="valueProps" data-hover="Stick Geeky, Cute Stuff to Things" title="Vinyl Decals"><span>Decals</span></button></Link> 
                <Link to="/gallery/jewelry"><button className="valueProps" data-hover="Reclaim Your Unique Style" title="Jewelry"><span>Jewelry</span></button></Link>
                <Link to="/gallery/textiles"><button className="valueProps" data-hover="Choose Your Own Adventure" title="Textiles and Home Decor"><span>Textiles</span></button></Link>
            </div>
            <div id="stakes" className="grid-container">   
            <div className="full-width-grid">      
                    <h1>Are you a Covert Geek?</h1>
                    <p className="more-info">So am I! I love making beautiful things for geeks of all sorts. Everyone is a geek about something. I understand geek culture and wanting to express yourself <em>your</em> way. That's why I create designs that allow you to display your geekiness with pride.</p><p className="more-info">No one should have to sacrifice their personal style just to fit in with the muggles. So, stop limiting your style and start displaying what makes you a geek unapologetically.</p> 
                    <h2>We are geeks. We are unique. We are beautifully fun.</h2>              
            </div>
            </div>
            <div id="testimonials">
                <div id="testimonials-headline"><h5>What are customers saying about my work?</h5></div>
                <div className="testimonial-card card-1">
                    <h6>"Fantastic necklace! She did a special order and it turned out perfectly for the cosplay Game of Thrones dinner! Definitely happy!"</h6>
                    <strong>–bellraponi</strong>
                </div> 
                <div className="testimonial-card card-2">
                    <h6>"I loved that the seller answered me back so fast and helped me get the dimensions I wanted (I asked if she could make it 20" × 30" and she did!). I can now proudly show off this poster in my house every time people go upstairs!"</h6>
                    <strong>–Regine</strong>
                </div> 
                <div className="testimonial-card card-2">
                    <h6>"EXTREMELY HAPPY!!! VERY IMPRESSED!! Quality of the lettering and the symbol is professional grade! Alexandria replied within minutes of placing my order with a thank you and order confirmation."</h6>
                    <strong>–John</strong>
                </div> 
            </div>
            <div id="commissions" className="grid-container">
                <div className="full-width-grid">
                    <h2>Don't see something you're geeking over?<br />No problem! I can custom design something for you.</h2>
                </div>
                <div className="full-width-grid">
                <div id="step-1">
                    <span className="fa-stack fa-3x">
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <strong className="fa-stack-1x text-primary-top">1</strong>
                    </span>
                    <h4>Fill out a <a href="/contact" as={Link} to="/contact" className="plan-link">Commission Form</a></h4>
                </div>
                <div id="step-2">
                    <span className="fa-stack fa-3x">
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <strong className="fa-stack-1x text-primary-top">2</strong>
                    </span>
                    <h4>I review and provide you a quote</h4>
                </div>
                <div id="step-3">
                    <span className="fa-stack fa-3x">
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <strong className="fa-stack-1x text-primary-top">3</strong>
                    </span>
                    <h4>I create a custom order on my shop</h4>
                </div>
                <div id="step-4">
                    <span className="fa-stack fa-3x">
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <strong className="fa-stack-1x text-primary-top">4</strong>
                    </span>
                    <h4>Once paid I create your masterpiece!</h4>
                </div>
                </div>
                <div className="full-width-grid"><a href="/contact" as={Link} to="/contact" className="btn btn-primary d-none d-md-block">Commission a Handcrafted Piece</a></div><div className="full-width-grid"><a href="/contact" as={Link} to="/contact" className="btn btn-primary d-xl-none d-lg-none d-md-none d-block">Commission a<br/>Handcrafted Piece</a></div>
                <div className="full-width-grid"><h5>I want you to love your purchase as much as I loved making it for you.</h5><p className="more-info"><a href="/contact" as={Link} to="/contact">Contact us</a> if you need help with your purchase.</p><p className="more-info">Need some inspiration or just want to see what I've made? Check out my present and past projects in the <a href="/gallery" as={Link} to="/gallery">Gallery</a>.</p></div>
            </div>
            </Container>
        )
    }
  }
