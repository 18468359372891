import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';
import Gallery from 'react-grid-gallery';

const IMAGES =
[{
        src: "../img/textiles_gallery/meat-popsicle.webp",
        thumbnail: "../img/textiles_gallery/meat-popsicle-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Negative I am a meat popsicle",
        alt: "Negative I am a meat popsicle",
        margin: 4
},
{
        src: "../img/textiles_gallery/mid-century-stars-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/mid-century-stars-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Mid-Century Clouds and Stars",
        alt: "Mid-Century Clouds and Stars",
        margin: 4
},
{
        src: "../img/textiles_gallery/panda-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/panda-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Ninja Panda",
        alt: "Ninja Panda",
        margin: 4
},
{
        src: "../img/textiles_gallery/proud-to-be-a-geek.webp",
        thumbnail: "../img/textiles_gallery/proud-to-be-a-geek-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Proud to be a Geek",
        alt: "Proud to be a Geek",
        margin: 4
},
{
        src: "../img/textiles_gallery/Rogue-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/Rogue-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Rogue Rebels",
        alt: "Rogue Rebels",
        margin: 4
},
{
        src: "../img/textiles_gallery/tears-in-rain-pattern.webp",
        thumbnail: "../img/textiles_gallery/tears-in-rain-pattern-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Tears in Rain",
        alt: "Tears in Rain",
        margin: 4
},
{
        src: "../img/textiles_gallery/vgk-alt-tartan-gold-variation-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/vgk-alt-tartan-gold-variation-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Knights Star Gold Tartan",
        alt: "Knights Star Gold Tartan",
        margin: 4
},
{
        src: "../img/textiles_gallery/vgk-alt-tartan-gray-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/vgk-alt-tartan-gray-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Knights Full of Stars Tartan",
        alt: "Knights Full of Stars Star Tartan",
        margin: 4
},
{
        src: "../img/textiles_gallery/vgk-alt-tartan-gray-variation-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/vgk-alt-tartan-gray-variation-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Knights Star Tartan",
        alt: "Knights Star Tartan",
        margin: 4
},
{
        src: "../img/textiles_gallery/vgk-alt-tartan-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/vgk-alt-tartan-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Knight Full of Stars and Swords Gold Tartan",
        alt: "Knight Full of Stars and Swords Gold Tartan",
        margin: 4
},{
        src: "../img/textiles_gallery/vgk-tartan-gold-variation-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/vgk-tartan-gold-variation-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Knight of Shields Gold Tartan",
        alt: "Knight of Shields Gold Tartan",
        margin: 4
},
{
        src: "../img/textiles_gallery/vgk-Spoonflower.webp",
        thumbnail: "../img/textiles_gallery/vgk-Spoonflower-thumb.webp",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Go Knights Go",
        alt: "Go Knights Go",
        margin: 4
}
]

export default class Textiles extends Component {

render () {
    return (
        <div className="main-section">
          <div>
            <h1>Gallery</h1>
            <h2>Textiles</h2>
            <h3>Choose your own adventure!</h3>
            <h4>Sew your own masterpiece with our geeky fabric designs. Make your space your own with custom home decor.</h4>
            <div id="imageGallery" className="space-above space-below center">
                <p>Click a thumbnail to view a larger image.</p>
                <Gallery images={IMAGES} className="gallery" />
                <p>&nbsp;</p>
                <h3 className="gimmeSpace">Purchase Fabric and Home Decor on <a href="https://www.spoonflower.com/profiles/vixenvision" className="etsy" title="Spoonflower">Spoonflower</a></h3>
                <p>In the design gallery, click on a pattern to view all the options available such as fabric, wallpaper, living & dining decor, and face masks.</p>
                <h2>Open for commissions!</h2>
                <div>Fill out our <a href="/contact" as={Link} to="/contact" title="Contact Us">commission request form</a> if you're interested in a commission piece.</div> 
            </div> 
          </div> 
        </div>
        )
    }
}