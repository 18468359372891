import React, { Component } from 'react';
import { Container, Lead, BDiv, BP} from 'bootstrap-4-react';
import './About.css';

export default class About extends Component {
  render() {
    return (
      <Container as="about" role="document" id="about">
        <BDiv className="main-section">
          <h1>About</h1>
          <BDiv>
            <img src="/img/AW_030-Galaxy-Girl-300x450.webp" alt="Alexandria Wagner - Galaxy Girl" className="bioImage" />
            <Lead className="space-below">
            <BP>I'm Alexandria Wagner–a technogeek and multidisciplinary artist who creates decals, digital art posters, paintings, jewelry, accessories, decor, and more in Nevada. I also do freelance graphic design and web development.  
            </BP>
            <BP>I love creating geeky objects that are beautiful as well as fun. My art celebrates nature, astronomy, gaming, literature, sci-fi, and fantasy.</BP>
            <BP>When I was a kid, we used to move around a lot. It seemed with each new school I missed out on the home economics semester but always made it in time for the art semester. I believe the universe was trying to tell me something. I was always creating something: drawing, writing, and making friendship bracelets to sell at school. My entrepreneurial spirit was thriving early in my life.</BP>
            <BP>I studied music and minored in computer science when I first went off to college. The meticulous nature of music education sort of killed my love for that and I switched to psychology. I ended up dropping out of Arizona State to move to Nevada and work in the news business.</BP>
            <BP>For several years, I worked as a managing editor and an art director for newspapers, magazines, and television before I found a passion for web design and development. My love for tech and the web sent me back to school to achieve a Bachelor of Science in Information Technology as well as a Master’s in Information Systems. I’ve been working in tech ever since–though I never really stopped being a designer and artist.</BP>
            <BP>I now have over 30 years of design and over 15 years of web development experience. While I enjoy making web experiences, I much prefer helping others customize their own experience.</BP>
            <BP>In 2015, I attended Salt Lake Fan Fusion and assisted my friends Natalie and Morgan Carpenter from <a href="http://www.apocalypsetoo.com" title="Apocalypse Too">Apocalypse Too</a> in their booth. I had such a great time that I made the decision to start making jewelry and digital art again to sell at conventions.</BP>
            <BP>That’s when Vixen Vision Design was born! I chose the name because I love foxes and my art reflects how I see the world.</BP>
            <BP>During the pandemic, I fell in love with painting nebulae and galaxies, experimenting with ways to combine my decal art with my canvas art and I fell in love with painting and have started to incorporate more subjects than just space. Most of my paintings are impressionist though I do abstract as well. </BP>
            <BP>I still exhibit at comic and pop culture conventions with Natalie and Morgan but I also sell <a href="https://vixenvisiondesign.shop" title="Vixen Vision Design's Shop">art, decor, and jewelry</a> online as well as fabric designs on <a href="https://www.spoonflower.com/profiles/vixenvision" title="Spoonflower">Spoonflower</a>.</BP>
            <BP>Feel free to reach out to me by email at <a href="mailto:alex@vixenvisiondesign.com">alex@vixenvisiondesign.com</a> if you have any questions about my work or want to collaborate with me to create something unique.</BP>
            <BP>Live long and prosper!</BP>
            </Lead>
          </BDiv>
          <BDiv className="main-section space-above space-below">
            <img src="/img/vvd-dice-tower-west.webp" alt="Vixen Vision Design Con Display" className="featuredImage" />
          </BDiv>
        </BDiv>
      </Container>
    )
  }
}