import React, { Component } from 'react';
import { Navbar, Nav, BSpan, BDiv } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import './Footer.css';
import './About/About';
import './Gallery/Gallery';
import './Contact/Contact';
import './Home/Home';
import './Privacy/Privacy';
import './Terms/Terms';

export default class Footer extends Component {
  render() {
    return (
      <Navbar expand="lg" as="footer" role="contentinfo" className="footer">
          <BDiv className="footer-left col-sm-12 col-lg-4">
                <BDiv className="socialmediaicons">
                    <a href="https://www.facebook.com/vixvisdesign" target="_blank" rel="noopener noreferrer" className="facebook" title="Facebook" aria-label="Facebook"><span>Facebook</span></a>
                    <a href="https://www.instagram.com/vixenvisiondesign/" target="_blank" rel="noopener noreferrer" className="instagram" title="Instagram" aria-label="Instagram"><span>Instagram</span></a>
                    <a href="https://twitter.com/vixen_vision" target="_blank" rel="noopener noreferrer" className="twitter" title="Twitter" aria-label="Twitter"><span>Twitter</span></a>
                    <a href="https://www.pinterest.com/vixenvisiondesign/" target="_blank" rel="noopener noreferrer" className="pinterest" title="Pinterest" aria-label="Pinterest"><span>Pinterest</span></a>
                    <a href="https://www.spoonflower.com/profiles/vixenvision" target="_blank" rel="noopener noreferrer" className="spoonflower" title="Spoonflower" aria-label="Spoonflower"><span>Spoonflower</span></a>
                </BDiv>
                <BDiv className="newsletter"><h6>Become a Vixen Vision Insider! Join our mailing list to 
stay informed about new products, events, and exclusive deals.</h6><a href="https://vixen-vision-design.ck.page/2c4013c0f7" className="button small" role="button">Sign Up Now</a></BDiv>
          </BDiv>
          <BDiv className="footer-center col-sm-12 col-lg-4">
              <Navbar.Brand><img src="/img/fox_symbol.svg" alt="Vixen Vision Design Fox" height="120px"  loading="lazy"/></Navbar.Brand>
          </BDiv>
          <BDiv className="footer-right col-sm-12 col-lg-4">
              <Navbar.Nav className="footer">
                <Nav.ItemLink href="/" as={Link} to="/home"> Home</Nav.ItemLink>
                <Nav.ItemLink href="/about" as={Link} to="/about"> About</Nav.ItemLink>
                <Nav.ItemLink href="/contact" as={Link} to="/contact"> Contact</Nav.ItemLink>
                <Nav.ItemLink href="/gallery" as={Link} to="/gallery"> Gallery</Nav.ItemLink>
              </Navbar.Nav>
              <Navbar.Nav>
                {/* <Nav.ItemLink href="https://ReclaimedMatter.com" as={Link} to="https://ReclaimedMatter.com">Blog</Nav.ItemLink> */}
                <Nav.ItemLink href="https://vixenvisiondesign.shop" as={Link} to="https://shop.vixenvisiondesign.shop">Shop</Nav.ItemLink>
                <Nav.ItemLink href="/privacy" as={Link} to="/privacy"> Privacy</Nav.ItemLink>
                <Nav.ItemLink href="/terms" as={Link} to="/terms"> Terms</Nav.ItemLink>
              </Navbar.Nav>
              <BSpan> 
                <h6>Copyright &copy; {this.getCurrentYear()} Vixen Vision Design. All rights reserved.</h6>
              </BSpan>
          </BDiv>
      </Navbar>
      
    )
  }
  getCurrentYear() {
    return new Date().getFullYear();
  }
}
