import React, { Component } from 'react';
import { Container, Lead, BDiv } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';

export default class Oops extends Component {
    render() {
      return (
        <Container as="oops" role="document">
            <BDiv className="main-section">
                <h1>Oops!</h1>
                <h2>Page Not Found</h2>
                <Lead>These aren't the droids you're looking for. Please try one of the links below.</Lead>
                <BDiv>
                    <ul id="page-list">
                        <li><a href="/" as={Link} to="/home"> Home</a></li>
                        <li><a href="/about" as={Link} to="/about"> About</a></li>
                        <li><a href="/contact" as={Link} to="/contact"> Contact</a></li>
                        <li><a href="/gallery" as={Link} to="/gallery"> Gallery</a></li>
                        <li><a href="/privacy" as={Link} to="/privacy"> Privacy</a></li>
                        <li><a href="/terms" as={Link} to="/terms"> Terms</a></li>
                    </ul>
                </BDiv> 
            </BDiv>
        </Container>
      )
    }
}