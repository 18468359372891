import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';

export default class JewelryCG extends Component {

render () {
    return (
        <div className="main-section">
          <div id="firstGallery" className="clearfix">
                <h1>Gallery</h1>
                <h2>Jewelry</h2>
                <h3>Hey there foxy, geek is beautiful!</h3>
                <h4>Wear jewelry that expresses your inner geek but masquerades as regular fare.</h4>
                <a href="https://vixenvisiondesign.shop/collections/jewelry" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop" rel="noopener noreferrer">Buy Jewelry</a>
                <img src="../img/jewelry-lines-covert-geek.webp" alt="Covert Geek" className="featuredImage space-above" />
                <p>Click a thumbnail to view a larger image.</p>
                <div id="covertGeekGallery" className="grid-container space-above space-below center">
                  <div id="covertGeek" className="full-width-grid">
                        <div className="decalItem"><img src="../img/jewelry_gallery/Copper-Shuttles-300x300.webp" alt="Copper Shuttles Earrings" loading="lazy" /><p className="artCaption">Copper Shuttles Earrings - SOLD</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/red-nebula-earrings-300x300.webp" alt="Red Nebula Galaxy Earrings" loading="lazy" /><p className="artCaption">Red Nebula Galaxy Earrings - SOLD</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/doctor-who-police-box-enamel-and-bead-earrings-includes-free-gift-bag" title="Doctor Who Police Box Enamel and Bead Earrings" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Earrings-Tardis-Enamel-300x300.webp" alt="Doctor Who Police Box Enamel and Bead Earrings" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Doctor Who Police Box Enamel and Bead Earrings</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/mjolnir-earrings-300x300.webp" alt="Thor's Hammer Silver Metal and Crystal Earrings" loading="lazy" /><p className="artCaption">Thor's Hammer Silver Metal and Crystal Earrings</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/cheshire-cat-charm-and-crystal-bead-earrings-includes-free-gift-bag" title="Cheshire Cat Charm and Crystal Bead Earrings" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Earrings-Cheshire-Cat-300x300.webp" alt="Cheshire Cat Charm and Crystal Bead Earrings" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Cheshire Cat Charm and Crystal Bead Earrings</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/mortal-instruments-shadowhunter-rune-silver-metal-and-crystal-earrings-includes-free-gift-bag" title="Mortal Instruments Shadowhunter Rune Silver Metal and Crystal Earrings" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Mortal-Instruments-earrings-300x300.webp" alt="Mortal Instruments Shadowhunter Rune Silver Metal and Crystal Earrings" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Mortal Instruments Shadowhunter Rune Earrings</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/red-nebula-earrings-300x300.webp" alt="Red Nebula Galaxy Earrings" loading="lazy" /><p className="artCaption">Red Nebula Galaxy Earrings - SOLD</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/antique-bronze-and-glass-bead-lightning-bolt-earrings-includes-free-gift-bag" title="Antique Bronze and Glass Bead Lightning Bolt Earrings" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/flash-earrings-300x300.webp" alt="Antique Bronze and Glass Bead Lightning Bolt Earrings" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Antique Bronze and Glass Bead Lightning Bolt Earrings</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/fairy-door-crystal-bead-earrings-includes-free-gift-bag" title="Fairy Door Crystal Bead Earrings" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/fairy-door-AB-300x300.webp" alt="Fairy Door Crystal Bead Earrings" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Fairy Door Crystal Bead Earrings</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/lucky-stars-and-planet-bangle-bracelet-last-one-ever-includes-free-gift-bag" title="Lucky Stars and Planet Bangle Bracelet" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Lucky-Stars-and-Planets-Bracelet-300x300.webp" alt="Lucky Stars and Planet Bangle Bracelet" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Lucky Stars and Planet Bangle Bracelet</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/little-mermaid-inspired-bangle-bracelet-includes-free-gift-bag" title="Little Mermaid Inspired Bangle Bracelet" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Little-Mermaid-Bracelet-300x300.webp" alt="Little Mermaid Inspired Bangle Bracelet" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Little Mermaid Inspired Bangle Bracelet</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/house-targaryen-dragon-bead-necklace-includes-free-gift-bag" title="House Targaryen Dragon Red, Black, Silver Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Targaryen-Red-Black-300x300.webp" alt="House Targaryen Dragon Red, Black, Silver Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">House Targaryen Dragon Red, Black, &amp; Silver Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/house-targaryen-dragon-bead-necklace-includes-free-gift-bag" title="House Targaryen Dragon Silver Red Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Targaryen-Necklace-Silver-300x300.webp" alt="House Targaryen Dragon Silver Red Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">House Targaryen Dragon Silver Red Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/house-targaryen-dragon-bead-necklace-includes-free-gift-bag" title="House Targaryen Dragon Silver Blue Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Targaryen-Blue-Black-300x300.webp" alt="House Targaryen Dragon Silver Blue Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">House Targaryen Dragon Silver Blue Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/house-baratheon-stag-black-and-yellow-bead-necklace-includes-free-gift-bag" title="House Baratheon Stag Black and Yellow Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/House-Baratheon-300x300.webp" alt="House Baratheon Stag Black and Yellow Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">House Baratheon Stag Black and Yellow Bead Necklace</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/lightning-bolt-necklace-300x300.webp" alt="Lightning Bolt Red and Yellow Enamel Pendant Bead Necklace" loading="lazy" /><p className="artCaption">Lightning Bolt Red &amp; Yellow Enamel Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/fiery-dragon-beaded-necklace-includes-free-gift-bag" title="Fiery Dragon Beaded Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/dragon-fire-necklace-300x300.webp" alt="Fiery Dragon Beaded Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Fiery Dragon Beaded Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/house-lannister-lion-pendant-bead-necklace-includes-free-gift-bag" title="House Lannister Lion Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/lannister-necklace-300x300.webp" alt="House Lannister Lion Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">House Lannister Lion Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/house-stark-dire-wolf-charm-and-bead-necklace-includes-free-gift-bag" title="House Stark Dire Wolf Charm and Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/house-stark-300x300.webp" alt="House Stark Dire Wolf Charm and Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">House Stark Dire Wolf Charm and Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/geeky-symbol-soldered-necklace-includes-free-gift-bag" title="Geeky Symbol Soldered Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Soldered-Star-Wars-pendants-300x300.webp" alt="Geeky Symbol Soldered Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Geeky Symbol Soldered Necklace</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/eye-of-agamoto-300x300.webp" alt="Doctor Strange - Eye of Agamoto Necklace" loading="lazy" /><p className="artCaption">Doctor Strange - Eye of Agamoto Necklace - SOLD</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/SteampunkStatement-Two-Tier-300x300.webp" alt="Steampunk Two-Tier Statement Necklace - Gold" loading="lazy" /><p className="artCaption">Steampunk Two-Tier Statement Necklace - Gold - SOLD</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/Deathly-Hallows-Necklace-300x300.webp" alt="Harry Potter - Deathly Hallows Necklace - Bronze" loading="lazy" /><p className="artCaption">Harry Potter - Deathly Hallows Necklace - Bronze - SOLD</p></div>
                  </div>
                </div>
          </div> 
          <div><hr></hr></div>
          <div id="secondGallery">            
                <h3>Reclaim your style!</h3>
                <h4>Wear jewelry with up-cycled and repurposed elements that show your unique flair.</h4>
                <img src="../img/ReclaimedMatter-new.webp" alt="Reclaimed Matter" className="space-above reclaimedMatterLogo" />
                <p>Click a thumbnail to view a larger image.</p>
                <div id="reclaimedMatterGallery" className="grid-container space-above space-below center">
                  <div id="reclaimedMatter" className="full-width-grid">
                        <div className="decalItem"><img src="../img/jewelry_gallery/Rhyolite-Waves-300x300.webp" alt="Rhyolite Waves Pendant and Beaded Necklace" loading="lazy" /><p className="artCaption">Rhyolite Waves Pendant &amp; Beaded Necklace</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/Reverse-Avocado-300x300.webp" alt="Reverse Avocado Glass Pendant on Leather Cord" loading="lazy" /><p className="artCaption">Reverse Avocado Glass Pendant on Leather Cord</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/Moonlit-Lagoon-Cuff-300x300.webp" alt="Moonlit Lagoon Cuff Bracelet" loading="lazy" /><p className="artCaption">Moonlit Lagoon Cuff Bracelet</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/Copper-Suns-300x300.webp" alt="Copper Suns Earrings" loading="lazy" /><p className="artCaption">Copper Suns Earrings - SOLD</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/aqua-and-lavender-paisley-clay-pendant-bead-necklace-includes-free-gift-bag" title="Aqua and Lavender Paisley Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/aqua-purple-paisley-300x300.webp" alt="Aqua and Lavender Paisley Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Aqua &amp; Lavender Paisley Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/multi-tone-foil-and-gold-starburst-clay-pendant-bead-necklace-includes-free-gift-bag" title="Multi-tone Foil and Gold Starburst Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/gold-starburst-300x300.webp" alt="Multi-tone Foil and Gold Starburst Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Foil &amp; Gold Starburst Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/hand-painted-sunflower-clay-pendant-and-bead-necklace-includes-free-gift-bag" title="Hand-Painted Sunflower Clay Pendant and Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/sunflower-300x300.webp" alt="Hand-Painted Sunflower Clay Pendant and Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Hand-Painted Sunflower Clay Pendant &amp; Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/hand-painted-bee-clay-pendant-and-bead-necklace-includes-free-gift-bag" title="Hand Painted Bee Clay Pendant and Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/bee-300x300.webp" alt="Hand Painted Bee Clay Pendant and Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Hand Painted Bee Clay Pendant and Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/floral-scroll-clay-pendant-bead-necklace-includes-free-gift-bag" title="Floral Scroll Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/peach-aqua-clay-pendant-300x300.webp" alt="Floral Scroll Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Floral Scroll Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/fleur-de-lis-clay-pendant-bead-necklace-includes-free-gift-bag" title="Fleur De Lis Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/fleur-de-lis-pendant-300x300.webp" alt="Fleur De Lis Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Fleur De Lis Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/red-and-white-enamel-abstract-art-pendant-bead-necklace-includes-free-gift-bag" title="Red and White Enamel Abstract Art Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Enamel-N-Red-White-300x300.webp" alt="Red and White Enamel Abstract Art Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Red &amp; White Enamel Abstract Art Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/green-leaf-and-burgundy-circle-layered-enamel-pendant-moonstone-garnet-and-peridot-bead-necklace-includes-free-gift-bag" title="Green Leaf and Burgundy Enamel Pendant Gemstone Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/leaf-garnet-necklace-300x300.webp" alt="Green Leaf and Burgundy Enamel Pendant Gemstone Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Green Leaf Enamel Pendant Gemstone Necklace</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/Enamel-Teal-300x300.webp" alt="Earthy Teal Round Enamel Pendant and Crystal and Glass Bead Necklace" loading="lazy" /><p className="artCaption">Teal Round Enamel Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/guitar-pick-enamel-pendant-lilac-and-teal-bead-necklace-includes-free-gift-bag" title="Guitar Pick Enamel Pendant Lilac and Teal Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Guitar-Pick-Pendant-Aqua-300x300.webp" alt="Guitar Pick Enamel Pendant Lilac and Teal Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Guitar Pick Enamel Pendant Lilac &amp; Teal Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/copper-enamel-pendant-and-garnet-and-glass-bead-necklace-includes-free-gift-bag" title="Copper Enamel Pendant and Garnet and Glass Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/copper-garnet-pendant-300x300.webp" alt="Copper Enamel Pendant and Garnet and Glass Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Copper Enamel Pendant Garnet &amp; Glass Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/black-etched-leaf-enamel-pendant-bead-necklace-includes-free-gift-bag" title="Black Etched Leaf Enamel Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/green-black-leaves-pendant-300x300.webp" alt="Black Etched Leaf Enamel Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Black Leaf Enamel Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/red-and-green-etched-leaf-enamel-pendant-with-garnet-and-magnesite-bead-necklace-includes-free-gift-bag" title="Red and Green Etched Leaf Enamel Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/green-red-leaves-pendant-300x300.webp" alt="Red and Green Etched Leaf Enamel Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Red &amp; Green Leaf Enamel Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/purple-and-white-layered-enamel-and-glass-pendant-bead-necklace-includes-free-gift-bag" title="Purple &amp; White Layered Enamel and Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/purple-white-enamel-glass-300x300.webp" alt="Purple and White Layered Enamel and Glass Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Purple &amp; White Enamel &amp; Glass Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/amethyst-and-enamel-pendant-bead-necklace-includes-free-gift-bag" title="Amethyst and Enamel Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Enamel-N-Red-Purple-300x300.webp" alt="Amethyst and Enamel Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Amethyst and Enamel Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/green-and-caramel-enamel-pendant-and-beaded-necklace-includes-free-gift-bag" title="Green and Caramel Enamel Pendant and Beaded Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Enamel-N-Green-Tan-300x300.webp" alt="Green and Caramel Enamel Pendant and Beaded Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Green &amp; Caramel Enamel Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/aqua-and-purple-enamel-pendant-bead-necklace-includes-free-gift-bag" title="Aqua and Purple Enamel Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Enamel-N-Purple-Aqua-300x300.webp" alt="Aqua and Purple Enamel Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Aqua and Purple Enamel Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/natural-citrine-and-clay-pendant-bead-necklace-includes-free-gift-bag" title="Natural Citrine and Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/citrine-300x300.webp" alt="Natural Citrine and Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Natural Citrine and Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/raw-tektite-and-clay-pendant-bead-necklace-includes-free-gift-bag" title="Raw Tektite and Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/black-tektite-300x300.webp" alt="Raw Tektite and Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Raw Tektite and Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/natural-yellow-moonstone-and-clay-pendant-bead-necklace-includes-free-gift-bag" title="Natural Yellow Moonstone and Clay Pendant Bead Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/moonstone-yellow-300x300.webp" alt="Natural Yellow Moonstone and Clay Pendant Bead Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Yellow Moonstone &amp; Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/amethyst-necklace-300x300.webp" alt="Natural Amethyst and Clay Pendant Bead Necklace" loading="lazy" /><p className="artCaption">Natural Amethyst &amp; Clay Pendant Bead Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/purple-and-gray-fused-glass-pendant-necklace-includes-free-gift-bag" title="Purple and Gray Fused Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Gray-Lavender-Cream-Glass-Pendant-300x300.webp" alt="Purple and Gray Fused Glass Pendant Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Purple and Gray Fused Glass Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/yellow-blue-and-green-multicolor-abstract-field-and-sky-fused-glass-pendant-necklace-includes-free-gift-bag" title="Abstract 'Field and Sky' Fused Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Field-and-Sky-Glass-Pendant-300x300.webp" alt="Abstract 'Field and Sky' Fused Glass Pendant Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Field and Sky Fused Glass Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/cobalt-blue-and-cream-abstract-fused-glass-pendant-necklace-includes-free-gift-bag" title="Cobalt Blue and Cream Abstract Fused Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/blue-cream-pendant-300x300.webp" alt="Cobalt Blue and Cream Abstract Fused Glass Pendant Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Cobalt Blue &amp; Cream Fused Glass Pendant Necklace</p></div>                       
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/bird-tail-repurposed-glass-pendant-necklace-includes-free-gift-bag" title="Bird Tail Repurposed Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/bird-tail-necklace-300x300.webp" alt="Bird Tail Repurposed Glass Pendant Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Bird Tail Repurposed Glass Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/blue-wheat-mid-century-style-repurposed-glass-pendant-necklace-includes-free-gift-bag" title="Blue Wheat Mid-Century Style Repurposed Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/blue-wheat-necklace-300x300.webp" alt="Blue Wheat Mid-Century Style Repurposed Glass Pendant Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Blue Wheat Repurposed Glass Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/wheat-and-bird-repurposed-glass-pendant-necklace-includes-free-gift-bag" title="Wheat and Bird Repurposed Glass Pendant Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/bird-wheat-necklace-300x300.webp" alt="Wheat and Bird Repurposed Glass Pendant Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Wheat &amp; Bird Repurposed Glass Pendant Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/rose-and-pinecone-repurposed-porcelain-victorian-charm-necklace-includes-free-gift-bag" title="Rose and Pinecone Repurposed Porcelain Victorian Charm Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/rose-pinecone-300x300.webp" alt="Rose and Pinecone Repurposed Porcelain Victorian Charm Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Rose &amp; Pinecone Repurposed Porcelain Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/victorian-lady-repurposed-porcelain-charm-necklace-includes-free-gift-bag" title="Victorian Lady Repurposed Porcelain Charm Necklace" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/victorian-lady-300x300.webp" alt="Victorian Lady Repurposed Porcelain Charm Necklace" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Victorian Lady Repurposed Porcelain Necklace</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/amazonite-and-clear-crystal-earrings-and-necklace-set-with-blue-and-white-enameled-pendant-includes-free-gift-bag" title="Amazonite and Crystal Enameled Pendant and Earrings Set" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/blue-and-white-set-300x300.webp" alt="Amazonite and Crystal Enameled Pendant and Earrings Set" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Amazonite &amp; Crystal Enameled Jewelry Set</p></div>
                        <div className="decalItem"><div className="overlay-item"><a href="https://vixenvisiondesign.shop/products/triskele-antique-bronze-and-crystal-earrings-and-necklace-set-includes-free-gift-bag" title="Triskele Antique Bronze and Crystal Earrings and Necklace Set" target="_blank" rel="noopener noreferrer"><img src="../img/jewelry_gallery/Triskele-Antique-Bronze-Set-300x300.webp" alt="Triskele Antique Bronze and Crystal Earrings and Necklace Set" loading="lazy" /></a><div class="overlay buy-overlay"><div class="text-overlay">BUY NOW</div></div></div><p className="artCaption">Triskele Antique Bronze &amp; Crystal Jewelry Set</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/shroom-drop-2022-300x300.webp" alt="Shroom Drop 2022 Hand-Painted Clay Mushroom Earrings" loading="lazy" /><p className="artCaption">Hand-Painted Clay Mushroom Earrings - SOLD</p></div>
                        <div className="decalItem"><img src="../img/jewelry_gallery/raw-brown-moonstone-pendant-300x300.webp" alt="Raw Brown Moonstone and Clay Pendant Bead Necklace" loading="lazy" /><p className="artCaption">Raw Brown Moonstone &amp; Clay Pendant Necklace - SOLD</p></div>
                  </div>
            </div>
          </div> 
          <div id="shopInfo">
                <p>&nbsp;</p>      
                <h2>Open for commissions!</h2>
                <div>Fill out our <a href="/contact" as={Link} to="/contact" title="Contact Us">commission request form</a> if you're interested in a commission piece.</div>  
          </div>
        </div>
        )
    }
}