import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';

export default class VinylSwatches extends Component {

render () {
    return (
        <div className="main-section">
          <div>
            <h1>Gallery</h1>
            <h2>Vinyl Swatches</h2>
            <h4>These are the current colors and vinyl types available! Adhesive vinyl types include premium permanent and removable, smart permanent and removable, as well as specialty vinyl such as glitter or holographic. Heat transfer vinyl types include everyday vinyl, fluorescent, metallic and glitter. Some colors won't work for dark or light fabric so make sure to select a color that will work. </h4>
            <a href="https://vixenvisiondesign.shop/search?q=Vinyl" className="btn btn-primary" target="_blank" role="button" title="Vixen Vision Design's Shop" rel="noopener noreferrer">Buy Vinyl Projects</a>
            <div id="imageGallery" className="space-above space-below center">
              <div id="decalGallery" className="grid-container space-above space-below center">
                  <div id="vinylSwatches" className="full-width-grid">
                      <h5>Adhesive Vinyl Swatches</h5>
                      <h4 className="space-above">Premium Permanent</h4>
                      <p>Premium permanent vinyl has a glossy finish and is great for coasters, drinkware, notebook, laptop, and car decals, outdoor signs, and exterior windows. It's also good for surfaces where you don't intend to remove the decal such as decor projects. </p>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-white.webp" alt="White" title="White"/><br></br>White</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-silver.webp" alt="Silver" title="Silver" /><br></br>Silver</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-clay.webp" alt="Clay" title="Clay" /><br></br>Clay</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-griege.webp" alt="Griege" title="Griege" /><br></br>Griege</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-black.webp" alt="Black" title="Black" /><br></br>Black</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-gold.webp" alt="Gold" title="Gold" /><br></br>Gold</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-suntan.webp" alt="Suntan" title="Suntan" /><br></br>Suntan</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-permanent-bright-yellow.webp" alt="Bright Yellow" title="Bright Yellow" /><br></br>Bright Yellow</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-premium-vinyl-stone-yellow.webp" alt="Stone Yellow" title="Stone Yellow" /><br></br>Stone Yellow</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-coral.webp" alt="Coral" title="Coral" /><br></br>Coral</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-orange.webp" alt="Orange" title="Orange" /><br></br>Orange</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-tomato-red.webp" alt="Tomato Red" title="Tomato Red" /><br></br>Tomato Red</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/Oracal-651-glossy-red.webp" alt="Red" title="Red" /><br></br>Red</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-dark-red.webp" alt="Dark Red" title="Dark Red" /><br></br>Dark Red</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-permanent-melon.webp" alt="Melon" title="Melon" /><br></br>Melon</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-premium-vinyl-light-pink.webp" alt="Light Pink" title="Light Pink" /><br></br>Light Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-permanent-fuschia.webp" alt="Fuschia" title="Fuschia" /><br></br>Fuschia</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium_vinyl-permanent-blush.webp" alt="Blush" title="Blush" /><br></br>Blush</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-premium-vinyl-light-purple.webp" alt="Light Purple" title="Light Purple" /><br></br>Light Purple</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-ultraviolet.webp" alt="Ultraviolet" title="Ultraviolet" /><br></br>Ultraviolet</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-blue.webp" alt="Blue" title="Blue" /><br></br>Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-permanent-aqua.webp" alt="Aqua" title="Aqua" /><br></br>Aqua</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-premium-vinyl-light-blue.webp" alt="Light Blue" title="Light Blue" /><br></br>Light Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/oracal-651-glossy-permanent-turquoise.webp" alt="Turquoise" title="Turquoise"/><br></br>Turquoise</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-permanent-teal.webp" alt="Teal" title="Teal" /><br></br>Teal</div> 
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium_vinyl-permanent-mint.webp" alt="Mint" title="Mint" /><br></br>Mint</div>   
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-kelly-green.webp" alt="Kelly Green" title="Kelly Green" /><br></br>Kelly Green</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-premium-vinyl-lime-green.webp" alt="Lime Green" title="Lime Green" /><br></br>Lime Green</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-forest-green.webp" alt="Forest Green" title="Forest Green" /><br></br>Forest Green</div>                      
                      <hr></hr>
                      <h4 className="space-above">Smart Permanent</h4>
                      <p>Smart permanent vinyl is more weatherproof than premium permanent vinyl and can be cut in greater lengths–11.7" tall by up to 12' long! This makes it perfect for large cuts for signs and dishwasher safe drinkware. Not all colors for available for all roll lengths. Black and white are the only colors available at 21' or longer. An &#42; indicates that the color is available at both 3' and 12' roll lengths. A &dagger; indicates available at longer than 12' Depending on your project, a fresh roll may need to ordered. Other colors and finishes can be special ordered such as holographic, glitter, and metallic but are not kept in stock. </p>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/black.webp" alt="Black" title="Black"/><br></br>Black&dagger;</div>                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/white.webp" alt="White" title="White"/><br></br>White&dagger;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/red.webp" alt="Red" title="Red" /><br></br>Red&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Maize.webp" alt="Maize" title="Maize" /><br></br>Maize&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Ocean.webp" alt="Ocean" title="Ocean" /><br></br>Ocean&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Grass.webp" alt="Grass" title="Grass" /><br></br>Grass&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Orange.webp" alt="Orange" title="Orange" /><br></br>Orange</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Mint.webp" alt="Mint" title="Mint" /><br></br>Mint</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Blue.webp" alt="Blue" title="Blue" /><br></br>Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Party-Pink.webp" alt="Party Pink" title="Party Pink" /><br></br>Party Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Purple.webp" alt="Purple" title="Purple" /><br></br>Purple</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Light-Pink.webp" alt="Light-Pink" title="Light-Pink" /><br></br>Light-Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Aqua.webp" alt="Aqua" title="Aqua" /><br></br>Aqua</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/champagne-metallic.webp" alt="Champagne Metallic" title="Champagne Metallic" /><br></br>Champagne Metallic</div>
                      <hr></hr>
                      <h4 className="space-above">Premium Removable</h4>  
                      <p>Removable vinyl has a matte finish and is great for wall decor, nail decals, seasonal decor, stencils, resist painting, labels, and bumpers. Good for painted surfaces where you may want to remove the decal without damaging the paint.</p>                   
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-white.webp" alt="White" title="White"/><br></br>White</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-permanent-clay.webp" alt="Clay" title="Clay" /><br></br>Clay</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-silver.webp" alt="Silver" title="Silver" /><br></br>Silver</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-black.webp" alt="Black" title="Black" /><br></br>Black</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-gold.webp" alt="Gold" title="Gold" /><br></br>Gold</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-removable-vivid-blue.webp" alt="Vivid Blue" title="Vivid Blue" /><br></br>Vivid Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-MS-premium-removable-blue-calico.webp" alt="Blue Calico" title="Blue Calico" /><br></br>Blue Calico</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-MS-premium-removable-caribbean-blue.webp" alt="Caribbean Blue" title="Caribbean Blue" /><br></br>Caribbean Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-MS-premium-removable-pink-flamingo.webp" alt="Pink Flamingo" title="Pink Flamingo" /><br></br>Pink Flamingo</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-premium-vinyl-permanent-fuschia.webp" alt="Fuschia" title="Fuschia" /><br></br>Fuschia</div>
                      <hr></hr>
                      <h4 className="space-above">Smart Removable</h4>
                      <p>Smart removable vinyl can be cut in greater lengths–11.7" tall by up to 12' long! This makes it perfect for large cuts for wall decor. Not all colors for available for all roll lengths. Black and white are the only colors available at 21' or longer. An &#42; indicates that the color is available at both 3' and 12' roll lengths. A &dagger; indicates available at longer than 12' Depending on your project, a fresh roll may need to ordered. Other colors and finishes can be special ordered such as metallics but are not kept in stock. </p>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/black.webp" alt="Black" title="Black"/><br></br>Black&dagger;</div>                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/white.webp" alt="White" title="White"/><br></br>White&dagger;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/red.webp" alt="Red" title="Red" /><br></br>Red&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Maize.webp" alt="Maize" title="Maize" /><br></br>Maize&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Ocean.webp" alt="Ocean" title="Ocean" /><br></br>Ocean&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Grass.webp" alt="Grass" title="Grass" /><br></br>Grass&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Orange.webp" alt="Orange" title="Orange" /><br></br>Orange</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Mint.webp" alt="Mint" title="Mint" /><br></br>Mint</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Blue.webp" alt="Blue" title="Blue" /><br></br>Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Party-Pink.webp" alt="Party Pink" title="Party Pink" /><br></br>Party Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Purple.webp" alt="Purple" title="Purple" /><br></br>Purple</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Light-Pink.webp" alt="Light-Pink" title="Light-Pink" /><br></br>Light-Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/smart/Aqua.webp" alt="Aqua" title="Aqua" /><br></br>Aqua</div>
                      <hr></hr>
                      <h4 className="space-above">Specialty</h4>
                      <p>Good for decor, water bottles, tumblers, and glasses. Holographic Sparkle, Shimmer, and Threads vinyl are water resistant. Most are recommended for indoor use but can use used for outdoors in certain situations due to their water resistance. These types of vinyl requires a little more TLC.</p>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-threads-silver.webp" alt="Silver Threads" title="Silver Threads" /><br></br>Silver Threads</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-threads-gold.webp" alt="Gold Threads" title="Gold Threads" /><br></br>Gold Threads</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-threads-gunmetal.webp" alt="Gunmetal Threads" title="Gunmetal Threads" /><br></br>Gunmetal Threads</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-silver.webp" alt="Silver Sparkle" title="Silver Sparkle" /><br></br>Silver Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-gold.webp" alt="Gold Sparkle" title="Gold Sparkle" /><br></br>Gold Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-whispering-blue.webp" alt="Whispering Blue Sparkle" title="Whispering Blue Sparkle" /><br></br>Whispering Blue Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-dew-green.webp" alt="Dew Green Sparkle" title="Dew Green Sparkle" /><br></br>Dew Green Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-blue.webp" alt="Blue Sparkle" title="Blue Sparkle" /><br></br>Blue Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-watermelon.webp" alt="Watermelon Sparkle<" title="Watermelon Sparkle<" /><br></br>Watermelon Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-sparkle-gunmetal.webp" alt="Gunmetal Sparkle" title="Gunmetal Sparkle" /><br></br>Gunmetal Sparkle</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-crystals-silver.webp" alt="Silver Crystals" title="Silver Crystals" /><br></br>Silver Crystals</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-blue.webp" alt="Holographic Blue" title="Holographic Blue" /><br></br>Holographic Blue</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-opal.webp" alt="Holographic Opal" title="Holographic Opal" /><br></br>Holographic Opal</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-permanent-holographic-pink.webp" alt="Holographic Pink" title="Holographic Pink" /><br></br>Holographic Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-shimmer-vinyl-purple.webp" alt="Shimmer Purple" title="Shimmer Purple" /><br></br>Shimmer Purple</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-shimmer-vinyl-fuchsia.webp" alt="Shimmer Fuchsia" title="Shimmer Fuchsia" /><br></br>Shimmer Fuchsia</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/cricut-glitter-vinyl-black.webp" alt="Black Glitter" title="Black Glitter" /><br></br>Black Glitter</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/VViVid-DECO65-Chrome-Rose-Gold.webp" alt="Chrome Rose Gold" title="Chrome Rose Gold" /><br></br>Chrome Rose Gold</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/Oracal-651-Intermediate-Copper.webp" alt="Copper Glossy" title="Copper Glossy" /><br></br>Copper Glossy</div>
                  </div>
                  <div id="decalsHTV" className="full-width-grid">
                      <h5>Heat Transfer Vinyl Swatches</h5>
                      <h4 className="space-above">Everyday Iron-On</h4>
                      <p>Cricut Brand  Everyday Iron-On with StrongBond™ lasts for 50+ washes. Wash and tumble dry inside out safe. Most colors are semi-gloss and work on light or dark; an &#42; indicates that the color is great for light only and a &dagger; indicates it's great for dark only. Other colors and finishes can be special ordered but are not kept in stock. </p>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-black.webp" alt="Black" title="Black"/><br></br>Black&#42;</div>                      
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-white.webp" alt="White" title="White"/><br></br>White&dagger;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-GOLD.webp" alt="Gold" title="Gold" /><br></br>Gold</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-silver.webp" alt="Silver" title="Silver" /><br></br>Silver&dagger;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-yellow.webp" alt="Yellow" title="Yellow" /><br></br>Yellow</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-pink.webp" alt="Pink" title="Pink" /><br></br>Pink</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-royal.webp" alt="Royal" title="Royal"/><br></br>Royal&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-purple.webp" alt="Purple" title="Purple"/><br></br>Purple&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-orange.webp" alt="Orange" title="Orange" /><br></br>Orange</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-RED.webp" alt="Red" title="Red" /><br></br>Red</div>   
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-cherry.webp" alt="Cherry" title="Cherry" /><br></br>Cherry</div> 
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-party-pink.webp" alt="Party Pink" title="Party Pink" /><br></br>Party Pink</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-lilac.webp" alt="Lilac" title="Lilac" /><br></br>Lilac</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-ocean.webp" alt="Ocean" title="Ocean" /><br></br>Ocean</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-bright-teal.webp" alt="Bright Teal" title="Bright Teal" /><br></br>Bright Teal</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-kelly.webp" alt="Kelly" title="Kelly" /><br></br>Kelly</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-everyday-iron-on-olive.webp" alt="Olive" title="Olive" /><br></br>Olive</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-neon-green.webp" alt="Neon Green" title="Neon Green" /><br></br>Neon Green</div>    
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-neon-green.webp" alt="Neon Pink" title="Neon Pink" /><br></br>Neon Pink</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-black.webp" alt="Black Glitter" title="Black Glitter" /><br></br>Black Glitter</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-silver.webp" alt="Silver Glitter" title="Silver Glitter" /><br></br>Silver Glitter</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-white.webp" alt="White Glitter" title="White Glitter" /><br></br>White Glitter&dagger;</div> 
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-gold.webp" alt="Gold Glitter" title="Gold Glitter" /><br></br>Gold Glitter</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-red.webp" alt="Red Glitter" title="Red Glitter" /><br></br>Red Glitter</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-fluorescent-pink.webp" alt="Fluorescent Pink Glitter" title="Fluorescent Pink Glitter" /><br></br>Fluorescent Pink Glitter</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-fluorescent-green.webp" alt="Fluorescent Green Glitter" title="Fluorescent Green Glitter" /><br></br>Fluorescent Green Glitter</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-glitter-iron-on-fluorescent-blue.webp" alt="Fluorescent Blue Glitter" title="Fluorescent Blue Glitter" /><br></br>Fluorescent Blue Glitter</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-strong-bond-holographic-blue.webp" alt="Holographic-Blue" title="Holographic-Blue" /><br></br>Holographic-Blue&dagger;</div>
                      <hr></hr>
                      <h4 className="space-above">SportFlex Iron-On</h4>
                      <p>Cricut Brand SportFlex Iron-On with StrongBond™ is made for use with activeware. It's stretchable and flexible and is recommended for tech fabrics like polyester and nylon. Wash and tumble dry inside out safe. Most colors are semi-gloss and work on light or dark; an &#42; indicates that the color is great for light only and a &dagger; indicates it's great for dark only. Other colors and finishes can be special ordered but are not kept in stock. </p>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-sportflex-iron-on-metallic-lavender.webp" alt="Matte Metallic Lavender" title="Matte Metallic Lavender" /><br></br>Matte Metallic Lavender</div>  
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/cricut-sportflex-iron-on-metallic-ocean.webp" alt="Matte Metallic Ocean" title="Matte Metallic Ocean" /><br></br>Matte Metallic Ocean</div>  
                      <hr></hr>
                      <h4 className="space-above">Somolux</h4>  
                      <p>Somolux brand comes in both matte and glossy heat transfer vinyl and offers a few different colors than Cricut brand. An &#42; indicates that the finish is glossy and great for dark only.</p>                   
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/somolux-glossy-white-htv.webp" alt="White" title="White"/><br></br>White&#42;</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/somolux-black-htv.webp" alt="Black" title="Black" /><br></br>Black</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/somolux-gold-htv.webp" alt="Gold" title="Gold" /><br></br>Gold</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/somolux-silver-htv.webp" alt="Silver" title="Silver" /><br></br>Silver</div>
                      <div className="swatch"><img src="../img/decal_gallery/swatches/htv/somolux-lemon-green-htv.webp" alt="Lemon Green" title="Lemon Green" /><br></br>Lemon Green</div>
                      <hr></hr>
                  </div>
                </div>
                <h2>Open for commissions!</h2>
                <div>Fill out our <a href="/contact" as={Link} to="/contact" title="Contact Us">commission request form</a> if you're interested in a commission piece.</div> 
            </div> 
          </div> 
        </div>
        )
    }
}