import React, { Component } from 'react';
import { Navigator, Footer, Home, About, Gallery, Contact, Art, Decals, VinylSwatches, Jewelry, Textiles, ThoughtfulThurs, Privacy, Terms, Oops } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <React.Fragment>
          <Navigator />
            <Routes>
                <Route exact path="/about" element={<About/>} />
                <Route exact path="/gallery" element={<Gallery/>}/>
                <Route exact path="/gallery/art" element={<Art/>}/>
                <Route exact path="/gallery/decals" element={<Decals/>}/>                
                <Route exact path="/gallery/jewelry" element={<Jewelry/>}/>
                <Route exact path="/gallery/vinyl-swatches" element={<VinylSwatches/>}/>   
                <Route exact path="/gallery/textiles" element={<Textiles/>}/>
                <Route exact path="/gallery/thoughtful-thursday" element={<ThoughtfulThurs/>}/>
                <Route exact path="/contact" element={<Contact/>}/>             
                <Route exact path="/privacy" element={<Privacy/>}/>
                <Route exact path="/terms" element={<Terms/>}/>
                <Route exact path="/" element={<Home/>} />
                <Route element={<Oops/>} />
            </Routes>
            <Footer />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
